export default [
    {
        path: "/cadastros",
        name: "register",
        component: () =>
            import("@/views/pages/dashboard/register/Register.vue"),
        meta: {
            requiresAuth: true,
            role: ['forms.get', 'answers.get', 'customers.get', 'forms.create']
        },
    },
    {
        path: "/cadastros/novo",
        name: "register/new",
        component: () =>
            import("@/views/pages/dashboard/new-register"),
        meta: {
            requiresAuth: true,
            role: ['customers.create']
        },
    },
    {
        path: "/cadastros/importar",
        name: "register/import",
        component: () =>
            import("@/views/pages/dashboard/import-registers"),
        meta: {
            requiresAuth: true,
            role: ['customers.import']
        },
    },
    {
        path: "/cadastros/importar/:id",
        name: "register/review",
        component: () =>
            import("@/views/pages/dashboard/import-registers/Review.vue"),
        meta: {
            requiresAuth: true,
            role: ['customers.import']
        },
    },
    {
        path: "/cadastros/forms/novo",
        name: "forms/new",
        component: () =>
            import("@/views/pages/dashboard/new-form"),
        meta: {
            requiresAuth: true,
            role: ['forms.create']
        },
    },
    {
        path: "/cadastros/forms/:id/editar",
        name: "forms/edit",
        component: () =>
            import("@/views/pages/dashboard/new-form"),
        meta: {
            requiresAuth: true,
            role: ['forms.update']
        },
    },
    {
        path: "/campanhas/conteudos",
        name: "campaigns/contents",
        component: () => import("@/views/pages/dashboard/campaign_content/CampaignContent.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update', 'campaigns.delete']
        },
    },
    {
        path: "/campanhas/conteudos/novo",
        name: "campaigns/contents/new",
        component: () =>
            import("@/views/pages/dashboard/campaigns/new-campaign"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.create']
        },
    },
    {
        path: "/campanhas/conteudos/:id/editar",
        name: "campaigns/contents/edit",
        component: () =>
            import("@/views/pages/dashboard/campaigns/new-campaign"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.update']
        },
    },
    {
        path: "/campanhas",
        name: "campaigns",
        component: () =>
            import("@/views/pages/dashboard/campaigns"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update', 'campaigns.delete']
        },
    },
    {
        path: "/campanhas/nova",
        name: "campaigns/new",
        component: () =>
            import("@/views/pages/dashboard/campaigns/new-campaign"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.create']
        },
    },
    {
        path: "/campanhas/:id/editar",
        name: "campaigns/edit",
        component: () =>
            import("@/views/pages/dashboard/campaigns/new-campaign"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.update']
        },
    },
    {
        path: "/respostas",
        name: "responses",
        component: () =>
            import("@/views/pages/dashboard/responses/index.vue"),
        meta: {
            requiresAuth: true,
            role: ['answers.get', 'answers.create', 'answers.update', 'answers.delete']
        },
    },
    {
        path: "/respostas/criar",
        name: "responses/new",
        component: () => import("@/views/pages/dashboard/responses/Response"),
        meta: {
            requiresAuth: true,
            role: ['answers.create']
        },
    },
    {
        path: "/respostas/:id/editar",
        name: "responses/edit",
        component: () => import("@/views/pages/dashboard/responses/Response"),
        meta: {
            requiresAuth: true,
            role: ['answers.update']
        },
    },
    {
        path: "/chat",
        name: "chats",
        component: () =>
            import("@/views/pages/dashboard/messages/Chat.vue"),
        meta: {
            requiresAuth: true,
            role: ['chats.get']
        },
    },
    {
        path: "/chat/:phone",
        name: "chats/show",
        component: () =>
            import("@/views/pages/dashboard/messages/Chat.vue"),
        meta: {
            requiresAuth: true,
            role: ['chats.get']
        },
    },
    {
        path: "/relatorios",
        name: "reports",
        component: () => import("@/views/pages/dashboard/reports"),
        meta: {
            requiresAuth: true,
            role: ['reports.get']
        },
    },
    {
        path: "/relatorios/exportar",
        name: "reports/export",
        component: () => import("@/views/pages/dashboard/reports/ReportExport"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/configuracoes/:tab?/:type?",
        name: "settings",
        component: () => import("@/views/pages/dashboard/settings/index.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/disparos/modelos",
        name: "shots/patterns",
        component: () => import("@/views/pages/dashboard/shots-patterns/index.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update']
        },
    },
    {
        path: "/disparos/modelos/:id/editar",
        name: "shots/patterns/edit",
        component: () => import("@/views/pages/dashboard/shots-patterns/ShotPattern.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update']
        },
    },
    {
        path: "/disparos/modelos/novo",
        name: "shots/patterns/new",
        component: () => import("@/views/pages/dashboard/shots-patterns/ShotPattern.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update']
        },
    },
    {
        path: "/segmentacao/nova",
        name: "nova-segmentacao",
        component: () => import("@/views/pages/dashboard/new-segmentation/index.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.create', 'campaigns.update']
        },
    },
    {
        path: "/segmentacao/:id/editar/",
        name: "editar-segmentacao",
        component: () => import("@/views/pages/dashboard/new-segmentation/index.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.create', 'campaigns.update']
        }
    },
    {
        path: "/segmentacao",
        name: "segmentation",
        component: () => import("@/views/pages/dashboard/segmentation/Segmentation.vue"),
        meta: {
            requiresAuth: true,
            role: ['campaigns.get', 'campaigns.create', 'campaigns.update']
        },
    },
];
