import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// import { canNavigate } from "@/libs/acl/routeProtection";
import pages from "./routes/pages";
import dashboard from "./routes/dashboard";
import { verifyUserRole } from "@/api/functions";
import app from "@/main";

Vue.use(VueRouter);

const routes = [
    { path: "/", redirect: { name: "auth-login" } },
    ...dashboard,
    ...pages,
    {
        path: "*",
        redirect: "404",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes,
});

router.beforeEach((to, from, next) => {
    window.NProgress.start();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters["user/isAuthenticated"]) {
            if (to.name !== "auth-login")
                return next({
                    name: "auth-login",
                    query: { redirect: to.fullPath },
                });

            return next({ name: "auth-login" });
        }
    }

    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters["user/isAuthenticated"]) {
            if (
                app.$role([
                    "forms.get",
                    "answers.get",
                    "customers.get",
                    "forms.create",
                ])
            )
                return next({ name: "register", query: to.query });
            
            if (
                app.$role([
                    "campaigns.get",
                    "campaigns.create",
                    "campaigns.update",
                    "campaigns.delete",
                ])
            )
                return next({ name: "campaigns", query: to.query });
            
            if (
                app.$role([
                    "answers.get",
                    "answers.create",
                    "answers.update",
                    "answers.delete",
                ])
            )
                return next({ name: "responses", query: to.query });
            
            if (app.$role(["chats.get"]))
                return next({ name: "chats", query: to.query });
            
            if (app.$role(["reports.get"]))
                return next({ name: "reports", query: to.query });

            return next({ name: "settings", query: to.query });
        }
    }

    if (to.matched.some((record) => record.meta.role)) {
        if (!store.getters["user/isPlanActive"])
            return next({ name: "settings" });

        if (!verifyUserRole(to.meta.role, "or"))
            return next({ name: "error-404" });
    }

    if (
        from.name === "register/new" ||
        from.name === "register/import" ||
        from.name === "register"
    ) {
        const previous_p = btoa(from.name);
        localStorage.setItem("@taglab/previous-page", previous_p);
    }

    next();
});

// Desativa o carregamento da página após a inicialização
router.afterEach(() => {
    window.NProgress.done();
    // Remove o carregamento inicial
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) appLoading.style.display = "none";
});

export default router;
