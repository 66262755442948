import { Session } from "@/auth";
import moment from "moment";

export const getters = {
    get: (state) => {
        return state.user;
    },

    getExtraValues: (state) => {
        return state.extraValues;
    },

    isAuthenticated: (state) => {
        if (!state.session) {
            const session = new Session().load();
            return session != null;
        }
        return true;
    },

    isPlanActive: (state) => {
        if (!state.session)
            return false;

        const user = state.user;
        return user.type == 'owner' ? (user.expires_at != null && moment(user.expires_at) > moment()) : (user.root.expires_at != null && moment(user.root.expires_at) > moment());

    },

    getToken: (state) => {
        return state.token;
    },

    getAuth: (state) => {
        return { "headers": { "Authorization": "Bearer " + state.token } };
    },

    getPermissions: (state) => {
        if (!state.user || !state.user.permissions) return null;
        return state.user.permissions;
    },

    getType: (state) => {
        if (!state.user || !state.user.type) return null;
        return state.user.type;
    },

    getRoot: (state) => {
        if (!state.user || !state.user.root) return null;
        return state.user.root;
    },

    getPlan: (state) => {
        const user = state.user;
        const nullPlan = {
            _id: null,
            name: "Não Encontrado",
            label: 'not_found',
            price: "R$0,00",
            duration: 0,
            max_agents: 0,
            used_agents: 0,
            max_phones: 0,
            used_phones: 0,
        };

        if (!user || !user.plan) return nullPlan;
        return {
            ...user.plan,
            used_agents: user.used_agents || 0,
            used_phones: user.used_phones || 0,
        };
    },
}
